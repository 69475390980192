import { types } from 'mobx-state-tree';
import Result from './Result';

const Question = types
  .model('Question', {
    title: types.identifier,
    results: types.optional(types.array(Result), [])
  })

export default Question;
