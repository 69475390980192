import React from 'react';
import { observer } from 'mobx-react';
import App from '../stores/App'
import { BarChart, LineChart, PieChart, ColumnChart } from 'react-chartkick'
import 'chart.js'

// This is the main starting point, where it all begins.
const ChartsModule = observer(
  class ChartsModule extends React.Component {
    
    return_chart_type = (data) => {
      switch (App.chart_type) {
        case "bar":
          return <BarChart
            data={data}
            legend={false}
          />
        case "line":
          return <LineChart
            data={data}
            legend={false}
          />
        case "pie":
          return <PieChart
            data={data}
            legend={false}
          />
        case "donut":
          return <PieChart
            data={data}
            donut={true}
            legend={false}
          />
        case "column":
          return <ColumnChart
            data={data}
            legend={false}
          />
        default:
          return null
      }
    }
    
    return_reactions_chart_type = () => {
      return this.return_chart_type(App.nice_reaction_data())
    }
    
    return_poll_chart_type = () => {
      if(App.poll_data !== null){
        const graph_divs = App.poll_data.questions.map((question) => {
          return(
            <div className="clip__charts-poll-section" key={question.title}>
              <span>{question.title}</span>
              {this.return_chart_type(App.nice_poll_data(question.results))}
            </div>
          )
        })
        return(
          <div className="clip__charts-poll">
            <h3>{App.poll_data.title}</h3>
            <div className="clip__charts-poll-graphs">
              {graph_divs}
            </div>
          </div>
        )
      }
      return null
    }
    
    render() {
      if(App.is_loading){
        return(
          <div>Loading...</div>
        )
      }
      return App.type === "reactions" ? this.return_reactions_chart_type() : App.type === "poll" ? this.return_poll_chart_type() : null
    }
  }
);
export default ChartsModule