/*eslint require-yield: "off"*/
import { types, flow } from 'mobx-state-tree';
import Logger from './Logger';
import PlatformApi, {API_ERROR} from './../api/PlatformApi';
import Reaction from './Models/Reaction'
import Poll from './Models/Poll'

let clip_element = null
let timeout = null
let error_timeout = null

const App = types
	.model('App', {
		is_loading: types.optional(types.boolean, true),
		error: types.optional(types.string, ""),
		debug_enabled: types.optional(types.boolean, false),
		available_types: types.optional(types.array(types.string), ["poll", "reactions"]),
		type: types.maybeNull(types.string),
		available_chart_types: types.optional(types.array(types.string), ["bar", "line", "donut", "pie", "column"]),
		chart_type: types.maybeNull(types.string),
		show_chart: types.optional(types.boolean, false),
		api_id: types.maybeNull(types.string),// The ID used to fetch the results
		reaction_data: types.optional(types.array(Reaction), []),
		poll_data: types.maybeNull(Poll),
		refresh: types.optional(types.number, 0),
		use_test_data: types.optional(types.boolean, false)
	})
	.actions(self => ({

		hydrate: flow(function* (element) {
			clip_element = element
			self.error = ""
			if(element?.getAttribute("data-enable-debug") !== null){
				self.debug_enabled = true
			}
			if(element?.getAttribute("data-use-test-data") !== null){
				self.use_test_data = true
			}
			Logger.log("App:hydrate", element)
			self.is_loading = true
			
			// Optionally check if we are showing this module just for logged-in users
			if(element?.getAttribute("data-active-user-only") !== null){
				const is_active = document.querySelector('.user-active');
				Logger.log("App:hydrate:user_is_active_class", is_active)
				if(!is_active){
					// Check if we are inactive first
					const is_inactive = document.querySelector('.user-inactive')
					if(is_inactive){
						setTimeout(() => {
							App.set_error_message("No user is active yet... Waiting")
						}, 500)
					}
					else{
						setTimeout(() => {
							App.set_error_message("No user is active")
						}, 5000)
					}
					return
				}
			}
			
			// Now we check what API endpoint we want to use
			// Options are "poll" and "reaction"
			// Check against the list, so we only show it if it's correct
			const type = element?.getAttribute("data-type")
			const is_available_type = self.available_types.find(t => t === type)
			if(is_available_type){
				self.type = type
			}
			// Now we check what chart we want
			const chart_type = element?.getAttribute("data-chart-type")
			const is_available_chart_type = self.available_chart_types.find(t => t === chart_type)
			if(is_available_chart_type){
				self.chart_type = chart_type
			}			
			// Check what ID we want to use to look up in the api
			const api_id= element?.getAttribute("data-id")
			if(api_id !== null){
				self.api_id = api_id
			}
			
			// Check if we want to enable auto refresh of the data
			const refresh_count = element?.getAttribute("data-refresh")
			if(refresh_count !== null){
				self.refresh = Number(refresh_count)
			}
			Logger.log("App:hydrate:element_info", self.type, self.chart_type, self.api_id)
			Logger.log("App:hydrate:refresh?", self.refresh)
			
			// Now we want to make sure we have everything available to us
			// Then we can show the charts
			self.show_chart = self.chart_type !== null && self.type !== null && self.api_id !== null
			Logger.log("App:hydrate:show_chart", self.show_chart)
			
			if(!self.show_chart){
				App.set_error_message("App:hydrate: Not all params have been added!")
				return
			}
			
			App.cleanup_dom(element)
			// Now we want to go ahead and load the data
			var got_data = false
			if(self.type === "reactions"){
				got_data = yield App.fetch_and_set_reaction_data()
			}
			else if(self.type === "poll"){
				got_data = yield App.fetch_and_set_poll_data()
			}
			
			if(!got_data){
				// Now we want to go ahead and show results.
				App.set_error_message("App:hydrate:Something went wrong with the data fetch")
				self.show_chart = false
				return
			}
			
			self.is_loading = false
		}),

		set_is_loading: flow(function* (loading) {
			Logger.log("App:set_is_loading", loading)
			self.is_loading = loading
		}),

		set_error_message: flow(function* (message) {
			Logger.log("App:set_error_message", message)
			self.error = message
			self.is_loading = false
			clearTimeout(timeout)
			clearTimeout(error_timeout)
			error_timeout = setTimeout(() => {
				App.hydrate(clip_element)
			}, 8000)
		}),
		
		cleanup_dom: flow(function* (element) {
			Logger.log("App:cleanup_dom", element)
			if(!App.debug_enabled){
				// Let's clean up the DOM a little bit.
				element.removeAttribute('data-active-user-only')
			}
			element.setAttribute('data-module-did-load', "true")
		}),
		
		fetch_and_set_reaction_data: flow(function* () {
			Logger.log("App:fetch_and_set_reaction_data")
			var data = self.use_test_data ? yield PlatformApi.get_demo_reaction_results() : yield PlatformApi.get_reaction_results()
			Logger.log("App:fetch_and_set_reaction_data:data", data)
			if(data.reactions?.length && data !== API_ERROR){
				let reactions = []
				data.reactions.forEach((reaction) => {
					Logger.log("App:hydrate:data:reactions-data", reaction)
					const reaction_object = {
						id: reaction[1],
						votes: Number(reaction[0])
					}
					reactions.push(reaction_object)
				})
				Logger.log("App:hydrate:data:reactions-data:reactions", reactions)
				self.reaction_data = reactions
				App.set_auto_refresh()
				return true
			}
			else{
				Logger.log("App:hydrate:data:reactions-WRONG_DATA", data)
				data = null // Set to null to trigger error down below
				App.set_auto_refresh()
			}
			return false
		}),
		
		fetch_and_set_poll_data: flow(function* () {
			Logger.log("App:fetch_and_set_poll_data")
			var data = self.use_test_data ? yield PlatformApi.get_demo_poll_results() : yield PlatformApi.get_poll_results()
			Logger.log("App:fetch_and_set_poll_data:data", data.poll)
			if(data.poll !== null && data !== API_ERROR){
				// Let's check if we have questions
				let questions = []
				for (const [key, value] of Object.entries(data.poll.questions)){
					Logger.log("App:fetch_and_set_poll_data:questions", key, value)
					// Now we want to go ahead and make a nice object out of this.
					if(value !== null && value.choices !== null){
						let results = []
						for (const [k, v] of Object.entries(value.choices)){
							Logger.log("App:fetch_and_set_poll_data:choices", k, v)
							const result_object = {
								label: v.label,
								votes: Number(v.votes)
							}
							results.push(result_object)
						}
						const question_object = {
							title: value.content,
							results: results
						}
						questions.push(question_object)
					}
				}
				const poll_object = {
					id: data.poll.id,
					title: data.poll.title,
					questions: questions
				}
				Logger.log("App:hydrate:data:poll-data:nice_data", poll_object)
				self.poll_data = poll_object
				App.set_auto_refresh()
				return true
			}
			else{
				Logger.log("App:hydrate:data:reactions-WRONG_DATA", data)
				data = null // Set to null to trigger error down below
				App.set_auto_refresh()
			}
			return false
		}),
		
		set_auto_refresh: flow(function* () {
			Logger.log("App:set_auto_refresh", self.refresh)
			if(self.refresh !== null && self.refresh > 0){
				clearTimeout(timeout)
				if(self.type === "reactions"){
					timeout = setTimeout(() => {
						App.fetch_and_set_reaction_data()
					}, self.refresh)
				}
				else if(self.type === "poll"){
					timeout = setTimeout(() => {
						App.fetch_and_set_poll_data()
					}, self.refresh)
				}
			}
		}),

	}))
	.views(self => ({
		nice_reaction_data(){
			var object = []
			object = App.reaction_data.map((reaction) => {
				return [reaction.id, reaction.votes]
			})
			return object
		},
		nice_poll_data(questions){
			var object = []
			object = questions.map((question) => {
				return [question.label, question.votes]
			})
			return object
		},
	}))
	.create();

export default App;
