import { types } from 'mobx-state-tree';
import Question from './Question';

const Poll = types
  .model('Poll', {
    id: types.identifierNumber,
    title: types.maybeNull(types.string),
    questions: types.optional(types.array(Question), [])
  })

export default Poll;
