import React from 'react';
import { observer } from 'mobx-react';
import App from '../stores/App'
import ChartsModule from './../components/charts';

// This is the main starting point, where it all begins.
const AppModule = observer(
	class AppModule extends React.Component {

		componentDidMount = async () => {
			App.hydrate(this.props.element)
		}

		render() {
			if(App.show_chart){
				return(
					<div className="clip clip__charts-container">
						<ChartsModule />
					</div>
				)
			}
			return null
		}
	}
);
export default AppModule