import 'react-app-polyfill/ie11';
import './utils/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/index.scss';
import App from './modules/App'

const element = document.querySelector('[data-react-clip="charts"]')

if (element) {
  ReactDOM.render(
    <React.StrictMode>
      <App element={element} />
    </React.StrictMode>,
    element
  );
}
