import axios from 'axios';
import App from '../stores/App'
import Logger from '../stores/Logger'

export const API_ERROR = 1
export const API_DOMAIN = ``

class PlatformApi {
  
  // TODO
  async get_poll_results() {
    Logger.log('PlatformApi:get_poll_results')
    const poll = axios
      .get(`${ API_DOMAIN }/wp-json/totalpoll/v4/poll/${App.api_id}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      })
      .then(response => {
        Logger.log("RESPONSE::", response)
        if (response.data) {
          return response.data.data;
        }
        else {
          App.set_error_message("No data returned")
          return API_ERROR
        }
      })
      .catch(error => {
        Logger.log("ERROR::", error.toString());
        App.set_error_message(error.toString())
        return API_ERROR
      });
    return poll;
  }
  
  async get_reaction_results() {
    Logger.log('PlatformApi:get_reaction_results')
    const reactions = axios
      .get(`${ API_DOMAIN }/wp-json/reactions/v1/get/${App.api_id}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      })
      .then(response => {
        Logger.log("RESPONSE::", response)
        if (response.data) {
          return response.data;
        }
        else {
          App.set_error_message("No data returned")
          return API_ERROR
        }
      })
      .catch(error => {
        Logger.log("ERROR::", error.toString());
        App.set_error_message(error.toString())
        return API_ERROR
      });
    return reactions;
  }
  
  // TEMP
  async get_demo_reaction_results() {
    // This is a test REACTION endpoint:
    return {"success":true,"reactions":[["13","like"],["26","love"],["19","wow"]]}
  }
  
  async get_demo_poll_results() {

    // This is a test POLL endpoint:
    return {
       "poll":{
          "id":1011,
          "title":"Testumfrage",
          "permalink":"https://event.local/poll/testumfrage/",
          "questions":{
 
             "a8600bfd-6c03-44c3-bb71-b567c9bf6a65":{
 
                "uid":"a8600bfd-6c03-44c3-bb71-b567c9bf6a65",
                "content":"Hund oder Katze?",
                "settings":{
 
                   "selection":{
 
                      "minimum":1,
                      "maximum":2
                   }
                },
                "choices":{
 
                   "476adbf2-7294-4789-a095-074b215de7ef":{
 
                      "uid":"476adbf2-7294-4789-a095-074b215de7ef",
                      "type":"text",
                      "label":"Hund",
                      "votes":16,
                      "index":0,
                      "questionUid":"a8600bfd-6c03-44c3-bb71-b567c9bf6a65",
                      "rank":1
                   },
                   "a05b9f18-d984-476b-b556-1075ddf4e7c6":{
 
                      "uid":"a05b9f18-d984-476b-b556-1075ddf4e7c6",
                      "type":"text",
                      "label":"Katze",
                      "votes":5,
                      "index":1,
                      "questionUid":"a8600bfd-6c03-44c3-bb71-b567c9bf6a65",
                      "rank":2
                   }
                },
                "index":0,
                "votes":21
             },
             "67bcd72d-d31f-4a09-b641-17c40ea1e878":{
 
                "uid":"67bcd72d-d31f-4a09-b641-17c40ea1e878",
                "content":"Welche ist deine Lieblingsfarbe?",
                "settings":{
 
                   "selection":{
 
                      "minimum":1,
                      "maximum":1
                   }
                },
                "choices":{
 
                   "a39bd1ca-a319-41ee-a96c-e9893f63a330":{
 
                      "uid":"a39bd1ca-a319-41ee-a96c-e9893f63a330",
                      "type":"text",
                      "label":"Rot",
                      "votes":8,
                      "index":0,
                      "questionUid":"67bcd72d-d31f-4a09-b641-17c40ea1e878",
                      "rank":2
                   },
                   "7baba7a2-67a1-4ef3-9e7d-ff4ae2f403a6":{
 
                      "uid":"7baba7a2-67a1-4ef3-9e7d-ff4ae2f403a6",
                      "type":"text",
                      "label":"Gr\u00fcn",
                      "votes":10,
                      "index":1,
                      "questionUid":"67bcd72d-d31f-4a09-b641-17c40ea1e878",
                      "rank":1
                   },
                   "a1dc9733-054b-49a2-a001-856c49e02046":{
 
                      "uid":"a1dc9733-054b-49a2-a001-856c49e02046",
                      "type":"text",
                      "label":"Blau",
                      "votes":3,
                      "index":2,
                      "questionUid":"67bcd72d-d31f-4a09-b641-17c40ea1e878",
                      "rank":3
                   }
                },
                "index":1,
                "votes":21
             },
             "87c5be7c-9d34-426b-a2ec-cf9fb9a2dd75":{
 
                "uid":"87c5be7c-9d34-426b-a2ec-cf9fb9a2dd75",
                "content":"Wohin f\u00e4hrst du in den Urlaub?",
                "settings":{
 
                   "selection":{
 
                      "minimum":1,
                      "maximum":1
                   }
                },
                "choices":{
 
                   "fa0b68f6-a67e-4af5-b407-e8a2d58dca55":{
 
                      "uid":"fa0b68f6-a67e-4af5-b407-e8a2d58dca55",
                      "type":"text",
                      "label":"Italien",
                      "votes":5,
                      "index":0,
                      "questionUid":"87c5be7c-9d34-426b-a2ec-cf9fb9a2dd75",
                      "rank":2
                   },
                   "c6cf05eb-86a0-4f1e-9650-692eafe43730":{
 
                      "uid":"c6cf05eb-86a0-4f1e-9650-692eafe43730",
                      "type":"text",
                      "label":"Frankreich",
                      "votes":4,
                      "index":1,
                      "questionUid":"87c5be7c-9d34-426b-a2ec-cf9fb9a2dd75",
                      "rank":3
                   },
                   "ea27e824-d4c3-4f20-99e0-ad126175c438":{
 
                      "uid":"ea27e824-d4c3-4f20-99e0-ad126175c438",
                      "type":"text",
                      "label":"Spanien",
                      "votes":8,
                      "index":2,
                      "questionUid":"87c5be7c-9d34-426b-a2ec-cf9fb9a2dd75",
                      "rank":1
                   }
                },
                "index":2,
                "votes":17
             }
          },
          "fields":[
             
          ],
          "acceptingVotes":true,
          "hasVoted":false
       },
       "status":200
    }
  }

}

export default new PlatformApi()